import { ManufacturingGroup, ManufacturingUnitGroupPriority } from "api/manufacturing/units/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../../Panel.module.css";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useGroupColumns = (
  groups: ManufacturingGroup["elements"],
  selectedGroups: ManufacturingGroup["elements"],
  setSelectedGroups: React.Dispatch<React.SetStateAction<ManufacturingGroup["elements"]>>,
) => {
  const isGroupSelected = (id: string | number): boolean =>
    selectedGroups.some(group => group.id === id);

  const setGroup = (id: number | string): void => {
    if (isGroupSelected(id))
      return setSelectedGroups(prevGroups => prevGroups.filter(group => group.id !== id));
    const groupToAdd = groups.find(group => group.id === id);
    assertIsDefined(groupToAdd);
    setSelectedGroups(prevGroups => [...prevGroups, groupToAdd]);
  };

  const areSomeGroupsSelected = Boolean(
    selectedGroups.length &&
      groups.some(group => selectedGroups.some(selectedGroup => selectedGroup.id === group.id)),
  );

  const areAllSelected =
    groups.every(group => selectedGroups.some(selectedGroup => selectedGroup.id === group.id)) &&
    Boolean(selectedGroups.length);

  const setAllGroups = (): void => {
    if (areAllSelected) return setSelectedGroups([]);
    const notSelectedGroups = groups.filter(
      group => !selectedGroups.some(selectedGroup => selectedGroup.id === group.id),
    );
    setSelectedGroups(prevGroups => [...prevGroups, ...notSelectedGroups]);
  };

  return useCreateTableColumns<ManufacturingGroup["elements"][number]>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectedGroups",
        header: () => (
          <div className="d-flex align-items-end pb-2">
            <Checkbox
              checked={areAllSelected}
              indeterminate={areSomeGroupsSelected && !areAllSelected}
              onChange={setAllGroups}
              size="sx"
            />
          </div>
        ),
        size: 25,
        cell: info => {
          const group = info.getValue();
          return (
            <div className="pb-2">
              <Checkbox
                checked={isGroupSelected(group.id)}
                onChange={() => setGroup(group.id)}
                size="sx"
              />
            </div>
          );
        },
      }),
      columnHelper.text(row => row.name, {
        header: "tkanina",
        size: 161,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.accessor(row => row, {
        header: "zlecenie",
        size: 120,
        cell: info => {
          const group = info.getValue();
          return (
            <Typography
              color={
                group.priority === ManufacturingUnitGroupPriority.A
                  ? "danger600"
                  : group.priority === ManufacturingUnitGroupPriority.B
                  ? "orange600"
                  : "neutralBlack88"
              }
              fontSize="14"
              fontWeight={
                group.priority === ManufacturingUnitGroupPriority.A ||
                group.priority === ManufacturingUnitGroupPriority.B
                  ? "700"
                  : "600"
              }
            >
              {group.signature}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => row.orderSignature, {
        header: "zamówienie",
        size: 120,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: " ",
        size: 46,
        cell: info => {
          const priority = info.getValue();
          if (
            priority === ManufacturingUnitGroupPriority.A ||
            priority === ManufacturingUnitGroupPriority.B
          )
            return (
              <div
                className={styles.ticketIcon}
                style={{
                  borderColor:
                    colorPalette[
                      manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[priority]
                        .light.color
                    ],
                }}
              >
                {
                  manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[priority].light
                    .icon
                }
              </div>
            );
          return null;
        },
      }),
      columnHelper.stretchContent,
    ];
  });
};
