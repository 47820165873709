import { createPaginatedKey } from "api/keys";

export const indexesKeys = {
  indexes: {
    list: createPaginatedKey("indexes"),
    details: (indexId: string) => ["index", indexId],
    indexStatistics: (indexId: string) => ["indexStatistics", indexId],
  },
  packages: {
    list: createPaginatedKey("indexPackages"),
  },
};
