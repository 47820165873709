import { logbookActions } from "api/logbook/hooks";
import { UUID } from "api/types";
import { Button } from "components/miloDesignSystem/atoms/button/Button";
import { HistoryLog } from "components/miloDesignSystem/atoms/historyLog/HistoryLog";
import { MdiKeyboardArrowDown } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowDown";
import { MdiKeyboardArrowUp } from "components/miloDesignSystem/atoms/icons/MdiKeyboardArrowUp";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Spinner } from "components/miloDesignSystem/atoms/spinner/Spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { CommonError } from "components/utils";
import { useState } from "react";
import { queryString } from "utilities";
import { EmptyHistory } from "./EmptyHistory";

interface Props {
  close: () => void;
  state: {
    signature: string;
    unitId: UUID;
  } | null;
}

export const UnitHistory = ({ close, state }: Props) => {
  const search = queryString.stringify({
    pageSize: "999",
    manufacturingWorkingUnit: state?.unitId ?? "",
  });
  const [showPreviousEvents, setShowPreviousEvents] = useState(false);
  const { data: logs, isLoading, error } = logbookActions.useLogsQuery(search);

  if (!Boolean(logs.length)) return <EmptyHistory close={close} signature={state?.signature} />;

  return (
    <Modal
      close={close}
      isOpen
      width={630}
      title={
        <Typography fontSize="20" fontWeight="700">
          Historia zlecenia: {state?.signature}
        </Typography>
      }
    >
      <div className="px-3">
        <div className="py-2">
          {isLoading && <Spinner size={22} />}
          {error && <CommonError status={error._httpStatus_} />}
          {(showPreviousEvents ? logs : logs.slice(0, 3)).map(log => (
            <HistoryLog
              content={log.description}
              created={log.created}
              id={log.id}
              key={log.id}
              kind={log.kind}
              responsible={log.user}
            />
          ))}
          {logs.length > 3 && (
            <Button
              size="small"
              variant="gray"
              endIcon={showPreviousEvents ? MdiKeyboardArrowUp : MdiKeyboardArrowDown}
              className="text-uppercase mt-3 mb-2"
              onClick={() => setShowPreviousEvents(prev => !prev)}
            >
              {showPreviousEvents ? "Ukryj" : "Pokaż"} wcześniejsze zdarzenia
            </Button>
          )}
        </div>
        <div className="d-flex align-items-center pt-2 pb-3">
          <Button className="text-uppercase" onClick={close} size="medium" variant="transparent">
            Zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
