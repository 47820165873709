import { materialsActions } from "api/manufacturing/materials/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton/IconButton";
import { MdiCached } from "components/miloDesignSystem/atoms/icons/MdiCached";
import { MdiKeyboardTab } from "components/miloDesignSystem/atoms/icons/MdiKeyboardTab";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelHeader } from "components/utils/drawer";
import { useQuery, useStateModal, useToggle } from "hooks";
import { useDrawer } from "hooks/useDrawer";
import { ManualMaterialStockUpdate } from "../../manualMaterialStockUpdate/ManualMaterialStockUpdate";
import { OrderMaterialModal } from "../../DemandAndAvailabilityList";
import { OrderMaterial } from "../../orderMaterial/OrderMaterialModal";
import { MaterialAvailabilityStatus, MaterialListItem } from "api/manufacturing/materials/models";

export const PanelHeader = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("materialsDemandAndAvailability");
  const { data: material } = materialsActions.useMaterial(panelId, {});
  const manualMaterialStockUpdateModal = useToggle();
  const orderMaterialModal: OrderMaterialModal = useStateModal();

  if (!material) return null;

  return (
    <>
      <RightPanelHeader>
        <Typography fontSize="16" fontWeight="600" noWrap>
          {material.name}
        </Typography>

        <div className="d-flex align-items-center justify-content-end gap-1">
          <div className="d-flex align-items-center gap-2">
            <Button
              className="text-uppercase"
              onClick={manualMaterialStockUpdateModal.open}
              size="small"
              startIcon={MdiCached}
              variant="gray"
            >
              ręcznie zmień stan
            </Button>
            <Button
              className="text-uppercase"
              disabled={material.stock.stockStatus === MaterialAvailabilityStatus.NA}
              size="small"
              variant="gray"
              onClick={() => {
                orderMaterialModal.open({ materials: [(material as unknown) as MaterialListItem] });
              }}
            >
              zamów
            </Button>
          </div>
          <span className="divider line-divider" />
          <IconButton icon={MdiKeyboardTab} onClick={close} variant="transparent" />
        </div>
      </RightPanelHeader>
      {orderMaterialModal.isOpen && <OrderMaterial orderMaterialModal={orderMaterialModal} />}
      {manualMaterialStockUpdateModal.isOpen && (
        <ManualMaterialStockUpdate
          close={manualMaterialStockUpdateModal.close}
          material={material}
        />
      )}
    </>
  );
};
