import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { DashedLabel } from "../../../subcomponents/DashedLabel";
import { Panel } from "../../panel/Panel";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import {
  ManufacturingUnitGroupPriority,
  ManufacturingUnitGroupType,
} from "api/manufacturing/units/models";
import { ColumnType } from "../../ColumnView";
import { UUID } from "api/types";
import { useParams } from "react-router-dom";
import { ColumnWrapper } from "../ColumnWrapper";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { ColumnSearch } from "../../../subcomponents/ColumnSearch";
import { useQuery } from "hooks";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { ManufacturingTicket } from "../../shared/manufacturingTicket/ManufacturingTicket";
import { AttributeCategory } from "api/manufacturing/schemas/models";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingActions } from "api/manufacturing/actions";
import { queryString } from "utilities";
import { parseISO } from "date-fns";

interface Props {
  columnType: ColumnType | null;
  isDraggingMutationInProgress: boolean;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType | null>>;
}

export const InProgressSection = ({
  columnType,
  isDraggingMutationInProgress,
  setColumnType,
}: Props) => {
  const { query, updateQuery } = useQuery();
  const { stageId } = useParams<{ stageId: UUID }>();
  const { data: manufacturingStage } = useManufacturingStage(stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const search = manufacturingStagesUtils.getInProgressColumnSearch(
    query,
    stageId,
    manufacturingStage!.boardFormat,
    attributesKinds,
  );
  const {
    data: units,
    error,
    isLoading,
    isFetching,
    isPreviousData,
  } = manufacturingUnitsActions.useGetManufacturingUnitsGroups(search, { keepPreviousData: true });
  const { data: manufacturingItemCount } = manufacturingUnitsActions.useManufacturingItemCount({
    stageId,
    search: queryString.stringify({
      finishedAtFrom: query.finishedAtFrom,
      finishedAtTo: query.finishedAtTo,
    }),
  });

  if (isLoading && !isPreviousData)
    return (
      <div className="position-relative col-4 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner size={48} />
          </div>
        </ColumnWrapper>
      </div>
    );

  const sortedUnits = manufacturingStagesUtils.getSortedManufacturingUnitGroups(units);

  return (
    <div className="d-flex flex-column flex-1 col-4 px-0">
      <Droppable droppableId={manufacturingStagesConstants.IN_PROGRESS_DROPPABLE}>
        {(provided, snapshot) => (
          <div
            className="d-flex flex-1 flex-column overflow-hidden"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            <ColumnWrapper isDragOver={snapshot.isDraggingOver}>
              <div className="d-flex align-items-center justify-content-between py-2 gap-3">
                <div className="d-flex align-items-center gap-2">
                  <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
                    W trakcie
                  </Typography>
                  <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                    {manufacturingItemCount?.counts.inProgress || "brak"}
                  </Typography>
                  {(isFetching || isDraggingMutationInProgress) && <Spinner size={16} />}
                </div>
                <ColumnSearch
                  isLoading={isFetching}
                  queryKey="inProgressUnitsGroupsSearch"
                  onChange={search =>
                    updateQuery({ ...query, inProgressUnitsGroupsSearch: search ?? "" })
                  }
                  value={query["inProgressUnitsGroupsSearch"]}
                />
              </div>
              {error && (
                <div className="d-flex align-items-center justify-content-center h-100">
                  <CommonError status={error._httpStatus_} />
                </div>
              )}
              {!isLoading && !error && (
                <div className="d-flex flex-column gap-3">
                  {Object.entries(sortedUnits)
                    .filter(([, unitsSegment]) => Boolean(unitsSegment.length))
                    .map(([label, unitsSegment], index) => (
                      <div className="d-flex flex-column gap-1" key={label}>
                        {!label && index === 0 ? null : <DashedLabel label={label} />}
                        {unitsSegment
                          .sort((a, b) => {
                            const priorityOrder = { A: 1, B: 2, C: 3, "": 4 };
                            return priorityOrder[a.priority] - priorityOrder[b.priority];
                          })
                          .map((unit, index) => {
                            if (unit.type === ManufacturingUnitGroupType.MANUFACTURING_WORKING_UNIT)
                              return (
                                <Draggable
                                  draggableId={`unitInProgress;${unit.id}`}
                                  key={unit.id}
                                  index={index}
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      key={unit.id}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      style={manufacturingStagesUtils.getDraggableStyles(
                                        provided.draggableProps.style,
                                        snapshot,
                                      )}
                                    >
                                      <ManufacturingTicket
                                        attributes={ticket => (
                                          <>
                                            {ticket.attributes.map(attribute => (
                                              <div
                                                className="d-flex align-items-center gap-1"
                                                key={`in-progress-section-unit-attribute-${attribute.attribute.id}`}
                                              >
                                                {
                                                  manufacturingStagesConstants
                                                    .attributeCategoryIconsDict[
                                                    attribute.category.toUpperCase() as AttributeCategory
                                                  ]
                                                }
                                                <Typography
                                                  fontSize={
                                                    attribute.category.toUpperCase() ===
                                                    AttributeCategory.PRODUCT
                                                      ? "16"
                                                      : "14"
                                                  }
                                                  fontWeight={
                                                    attribute.category.toUpperCase() ===
                                                    AttributeCategory.PRODUCT
                                                      ? "700"
                                                      : "600"
                                                  }
                                                  noWrap
                                                >
                                                  {attribute.value.name}
                                                </Typography>
                                              </div>
                                            ))}
                                          </>
                                        )}
                                        downloadLabelFn={ticket =>
                                          manufacturingFileFactory.manufacturingItemPdf(
                                            [ticket.manufacturingItems[0]],
                                            ticket.signature!,
                                          )
                                        }
                                        isColumnActive={columnType === ColumnType.IN_PROGRESS}
                                        isDragged={snapshot.isDragging}
                                        isLatest={ticket =>
                                          manufacturingStagesUtils.isLatestTicket(
                                            unitsSegment.map(unit => parseISO(unit.startedAt)),
                                            ticket.recentlyMovedDate!,
                                          )
                                        }
                                        key={unit.id}
                                        onClick={ticket => {
                                          updateQuery({
                                            unitPanelId:
                                              query.unitPanelId && query.unitPanelId === ticket.id
                                                ? ""
                                                : ticket.id,
                                          });
                                          setColumnType(ColumnType.IN_PROGRESS);
                                        }}
                                        ticket={{
                                          attributes: unit.attributes,
                                          employee: unit.employee,
                                          group: unit.group?.signature,
                                          id: unit.id,
                                          isDeclined: unit.isDeclined,
                                          manufacturingItems: unit.manufacturingItems.map(
                                            item => item.id,
                                          ),
                                          priority: unit.priority as ManufacturingUnitGroupPriority,
                                          recentlyMovedDate: unit.startedAt,
                                          signature: unit.signature,
                                        }}
                                      />
                                    </div>
                                  )}
                                </Draggable>
                              );
                            return (
                              <Draggable
                                draggableId={`groupInProgress;${unit.id}`}
                                key={unit.id}
                                index={index}
                              >
                                {(provided, snapshot) => (
                                  <div
                                    key={unit.id}
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    style={manufacturingStagesUtils.getDraggableStyles(
                                      provided.draggableProps.style,
                                      snapshot,
                                    )}
                                  >
                                    <ManufacturingTicket
                                      attributes={ticket => (
                                        <>
                                          {ticket.attributes
                                            .filter(
                                              attribute =>
                                                attribute.value.id || attribute.value.name,
                                            )
                                            .map((attribute, index) => (
                                              <div
                                                className="d-flex align-items-center gap-1"
                                                key={`inProgressGroup-${attribute.value.id}-${attribute.attribute.id}-${index}`}
                                              >
                                                {attribute.category &&
                                                  manufacturingStagesConstants
                                                    .attributeCategoryIconsDict[
                                                    attribute.category.toUpperCase() as AttributeCategory
                                                  ]}
                                                <Typography
                                                  fontSize={
                                                    attribute.category?.toUpperCase() ===
                                                    AttributeCategory.PRODUCT
                                                      ? "16"
                                                      : "14"
                                                  }
                                                  fontWeight={
                                                    attribute.category?.toUpperCase() ===
                                                    AttributeCategory.PRODUCT
                                                      ? "700"
                                                      : "600"
                                                  }
                                                  noWrap
                                                >
                                                  {attribute.value.name}
                                                </Typography>
                                              </div>
                                            ))}
                                        </>
                                      )}
                                      downloadLabelFn={ticket =>
                                        manufacturingFileFactory.manufacturingItemPdf(
                                          ticket.manufacturingItems,
                                          ticket.signature!,
                                        )
                                      }
                                      isColumnActive={columnType === ColumnType.IN_PROGRESS}
                                      isDragged={snapshot.isDragging}
                                      isLatest={ticket =>
                                        manufacturingStagesUtils.isLatestTicket(
                                          unitsSegment.map(unit => parseISO(unit.startedAt)),
                                          ticket.recentlyMovedDate!,
                                        )
                                      }
                                      key={unit.id}
                                      onClick={ticket => {
                                        updateQuery({
                                          unitPanelId:
                                            query.unitPanelId && query.unitPanelId === ticket.id
                                              ? ""
                                              : ticket.id,
                                        });
                                        setColumnType(ColumnType.IN_PROGRESS);
                                      }}
                                      ticket={{
                                        attributes: unit.attributes,
                                        elementsCount: unit.elementsCount,
                                        id: unit.id,
                                        isDeclined: unit.isDeclined,
                                        manufacturingItems: unit.manufacturingItems.map(
                                          item => item.id,
                                        ),
                                        recentlyMovedDate: unit.startedAt,
                                        signature: unit.signature,
                                      }}
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </div>
                    ))}
                </div>
              )}
            </ColumnWrapper>
          </div>
        )}
      </Droppable>
      {columnType === "inProgress" && (
        <div className="d-flex flex-column mt-2">
          <Panel columnType={ColumnType.IN_PROGRESS} />
        </div>
      )}
    </div>
  );
};
