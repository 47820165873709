import { materialsActions } from "api/manufacturing/materials/actions";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody, RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { TitleSection } from "./titleSection/TitleSection";
import { PanelHeader } from "./panelHeader/PanelHeader";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { OrderSection } from "./orderSection/OrderSection";
import { Search } from "components/miloDesignSystem/molecules/search";
import { materialsApi } from "api/manufacturing/materials/api";
import { LastOrderSection } from "./LastOrderSection";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { Provider } from "./provider/Provider";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: material, error, isLoading } = materialsActions.useMaterial(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!material) return null;

  return (
    <DrawerRightPanel className="position-relative" key={material.id}>
      <PanelHeader />
      <DisabledOpacity disabled={isLoading}>
        <RightPanelBody>
          <TitleSection />
          <RightPanelSection>
            <InfoLabel title="jednostka">
              <Search.Async
                fetcherFn={materialsApi.getMaterialUnits}
                externalSelectedItem={{
                  text: `${material.unit.name || ""} (${material.unit.shortName || ""})`,
                  value: material.unit.id,
                }}
                mutationHook={materialsActions.usePatchMaterial}
                transformQueryData={unit => ({ id: material.id, unit: unit! })}
                isNullable={false}
                textFieldProps={{ size: "small" }}
              />
            </InfoLabel>
            <InfoLabel title="stan">
              <Typography fontSize="14" fontWeight="600">
                {material.stock.inStock} {material.unit.shortName}
              </Typography>
            </InfoLabel>
            <LastOrderSection alreadyOrdered={material.stock.alreadyOrdered} unit={material.unit} />
            <Provider />
            <div className="mt-3 mb-2">
              <Checkbox.Async
                size="sm"
                label="Zamawiaj wyłącznie pod zamówienie"
                checked={material.isCustomOrdered}
                mutationHook={materialsActions.usePatchMaterial}
                transformQueryData={isCustomOrdered => ({ id: material.id, isCustomOrdered })}
              />
            </div>
          </RightPanelSection>
          <OrderSection />
        </RightPanelBody>
      </DisabledOpacity>
    </DrawerRightPanel>
  );
};
