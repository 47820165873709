import { ManufacturerListItem } from "api/manufacturers/models";
import { ISODate, ISODateTime, UUID } from "api/types";
import { Assign } from "utility-types";

export type MaterialsTab = "in-use" | "withdrawn";

export enum MaterialStatus {
  IN_USE = "IN_USE",
  WITHDRAWN = "WITHDRAWN",
}

export enum MaterialAvailabilityStatus {
  HIGH = "HIGH",
  LOW = "LOW",
  VERY_HIGH = "VERY_HIGH",
  VERY_LOW = "VERY_LOW",
  NA = "NA",
  NONE = "NONE",
}

export type MaterialsListSearch = {
  tab: MaterialsTab;
  query: {
    [x: string]: string;
  };
};

export interface MaterialListItem {
  category: {
    id: number;
    name: string;
  } | null;
  deliveryDays: number;
  externalId: string;
  group: {
    id: UUID;
    name: string;
  } | null;
  id: UUID;
  name: string;
  packageSize: string;
  averageDeliveryDays: number;
  providers: {
    id: UUID;
    manufacturer: { id: number; name: string; taxId: string; email: string };
    isMain: boolean;
  }[];
  size: string;
  kind: ManufacturingMaterialKind;
  series: string;
  status: MaterialStatus;
  unit: {
    shortName: string;
    name: string;
  };
  isCustomOrdered: boolean;
  stock: {
    inStock: number;
    demandFromOrders: number;
    stockStatus: MaterialAvailabilityStatus;
    alreadyOrdered: number;
    lastWeekDemand: number;
  };
}

export interface Material {
  id: UUID;
  createdAt: ISODateTime;
  name: string;
  category: {
    id: UUID;
    name: string;
  } | null;
  unit: {
    id: UUID;
    name: string;
    shortName: string;
  };
  kind: ManufacturingMaterialKind;
  series: string;
  status: MaterialStatus;
  externalId: string;
  averageDeliveryDays: number;
  providers: MaterialListItem["providers"];
  isCustomOrdered: boolean;
  stock: MaterialListItem["stock"];
}

export enum ManufacturingMaterialKind {
  "RAW" = "RAW",
  "SEMI_FINISHED" = "SEMI_FINISHED",
}

export interface MaterialCategory {
  createdAt: ISODateTime;
  id: UUID;
  name: string;
}

export interface MaterialUnit {
  createdAt: ISODateTime;
  id: UUID;
  name: string;
  shortName: string;
}

export interface PostManufacturingMaterial {
  name: string;
  unit: UUID;
  category: UUID | null;
  externalId: string;
  kind: ManufacturingMaterialKind;
  averageDeliveryDays: number | null;
  manufacturer: { id: number; isMain: boolean } | null;
}

export interface ManufacturingMaterialOrder {
  id: UUID;
  name: MaterialListItem["name"];
  category: MaterialListItem["category"];
  unit: MaterialListItem["unit"];
  material: UUID;
  manufacturer: string | number | null;
  quantity: string;
  scheduledDeadline: ISODate | null;
}

export interface PostOrderManufacturingMaterial {
  defaultScheduledDeadline: boolean;
  delivery: number | null;
  areEmailLabelsRequired: boolean;
  orders: {
    id: UUID;
    isAlreadyOrdered?: boolean;
    name: MaterialListItem["name"];
    category: MaterialListItem["category"];
    unit: MaterialListItem["unit"];
    material: UUID;
    manufacturer: number | string | null;
    quantity: string;
    scheduledDeadline: ISODate | null;
  }[];
}

export interface PostMaterialManufacturer {
  materials: UUID[];
  manufacturers: ManufacturerListItem[];
}

export enum MaterialStockUpdateDirection {
  IN = "IN",
  OUT = "OUT",
}

export interface ManualMaterialStockUpdatePayload {
  direction: MaterialStockUpdateDirection;
  quantity: number;
}

export interface PostBulkOrderManufacturingMaterial {
  defaultScheduledDeadline: boolean;
  delivery: number | null;
  areEmailLabelsRequired: boolean;
}

export interface PostOrderManufacturingMaterialForStageList
  extends Assign<
    PostBulkOrderManufacturingMaterial,
    {
      orders: Assign<
        Omit<ManufacturingMaterialOrder, "unit">,
        {
          averageDeliveryDays: number;
          manufacturingWorkingUnit: UUID;
          manufacturingWorkingUnitSignature: string;
          manufacturers: {
            id: number;
            isMain: boolean;
            name: string;
          }[];
          unit: {
            id: UUID;
            shortName: string;
          };
          isAlreadyOrdered: boolean;
        }
      >[];
    }
  > {}
