import styled from "@emotion/styled";
import { ManufacturingRouteListItem, RouteManufacturingStatus } from "api/routes/models";
import { ColorPalette, colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { ReactNode } from "react";
import { dateFns } from "utilities";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturingStagesColumns = () => {
  return useCreateTableColumns<ManufacturingRouteListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row.color, {
        id: "color",
        header: " ",
        size: 24,
        cell: info => {
          const color = info.getValue();
          return <Square size={16} backgroundColor={color} />;
        },
      }),
      columnHelper.accessor(row => row.priority, {
        header: "priorytet",
        size: 54,
        cell: info => {
          const priority = info.getValue();
          return (
            <div className="d-flex gap-1 align-items-center">
              <Square size={14} backgroundColor={colorPalette[priorityDict[priority].color]} />
              <Typography fontSize="14" fontWeight="600">
                {priorityDict[priority].label}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.text(row => row.signature, {
        header: "sygnatura",
        size: 158,
        typographyProps: {
          fontSize: "14",
          fontWeight: "700",
        },
      }),
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 150,
        typographyProps: {
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.manufacturingStatus, {
        header: "status produkcji",
        size: 128,
        cell: info => {
          const manufacturingStatus = info.getValue();
          return manufacturingStatusToTagDict[manufacturingStatus];
        },
      }),
      columnHelper.accessor(row => row, {
        header: "załadunek",
        size: 168,
        cell: info => {
          const date = info.getValue().loadingDate;
          const time = info.getValue().loadingTime;
          const formattedTime = time?.length === 8 ? time.slice(0, -3) : time;
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="600" color="neutralBlack48">
                {time ? formattedTime : "brak godziny"}
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {date ? dateFns.format(new Date(date), "dd.MM.yyyy") : "brak daty"}
              </Typography>
            </div>
          );
        },
      }),
      columnHelper.accessor(row => row, {
        header: "wyjazd",
        size: 168,
        cell: info => {
          const date = info.getValue().departureDate;
          const time = info.getValue().departureTime;
          const formattedTime = time?.length === 8 ? time.slice(0, -3) : time;
          return (
            <div className="d-flex align-items-center gap-2">
              <Typography fontSize="14" fontWeight="600">
                {time ? formattedTime : "brak godziny"}
              </Typography>
              <Typography fontSize="14" fontWeight="600">
                {date ? dateFns.format(new Date(date), "dd.MM.yyyy") : "brak daty"}
              </Typography>
            </div>
          );
        },
      }),
    ];
  });
};

type Priority = ManufacturingRouteListItem["priority"];
const priorityDict: Record<Priority, { label: "A" | "B" | "C"; color: ColorPalette }> = {
  HIGH: { label: "A", color: "red300" },
  MEDIUM: { label: "B", color: "orange300" },
  LOW: { label: "C", color: "blue300" },
};

const manufacturingStatusToTagDict: Record<RouteManufacturingStatus, ReactNode> = {
  [RouteManufacturingStatus.FINISHED]: <Tag label="Przygotowana" variant="success" />,
  [RouteManufacturingStatus.IN_PROGRESS]: <Tag label="w trakcie" variant="warning" />,
  [RouteManufacturingStatus.PENDING]: (
    <Tag label="nie rozpoczęto" variant="quaternary" type="outlined" />
  ),
};

const Square = styled.div<{
  backgroundColor: string;
  size: number;
}>(({ backgroundColor, size }) => ({
  width: `${size}px`,
  height: `${size}px`,
  borderRadius: "2px",
  backgroundColor,
}));
