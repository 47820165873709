import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { AsyncInput } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import styles from "./RecipientInfoSection.module.css";

interface Props {
  tradingDocument: TradingDocument;
}

export const RecipientInfoSection = ({ tradingDocument }: Props) => {
  const companyNameMutation = usePatchTradingDocumentMutation();
  const firstNameMutation = usePatchTradingDocumentMutation();
  const lastNameMutation = usePatchTradingDocumentMutation();
  const streetMutation = usePatchTradingDocumentMutation();
  const postCodeMutation = usePatchTradingDocumentMutation();
  const cityMutation = usePatchTradingDocumentMutation();
  const phoneMutation = usePatchTradingDocumentMutation();
  const emailMutation = usePatchTradingDocumentMutation();
  const taxIdMutation = usePatchTradingDocumentMutation();
  const bankAccountNumberMutation = usePatchTradingDocumentMutation();

  return (
    <RightPanelSection title="Odbiorca">
      <div className="pb-2 flex-1 w-50">
        <AsyncInput
          disabled={companyNameMutation.isLoading}
          inProgress={companyNameMutation.isLoading}
          onChange={recipientCompanyName =>
            companyNameMutation.mutate({ id: tradingDocument.id, recipientCompanyName })
          }
          overwrites={asyncInputOverrides}
          placeholder="Nazwa firmy"
          label="Nazwa firmy"
          value={tradingDocument.recipientCompanyName}
        />
        <AsyncInput
          disabled={firstNameMutation.isLoading}
          inProgress={firstNameMutation.isLoading}
          onChange={recipientFirstName =>
            firstNameMutation.mutate({ id: tradingDocument.id, recipientFirstName })
          }
          overwrites={asyncInputOverrides}
          placeholder="Imię"
          label="Imię"
          value={tradingDocument.recipientFirstName}
        />
        <AsyncInput
          disabled={lastNameMutation.isLoading}
          inProgress={lastNameMutation.isLoading}
          onChange={recipientLastName =>
            lastNameMutation.mutate({ id: tradingDocument.id, recipientLastName })
          }
          overwrites={asyncInputOverrides}
          placeholder="Nazwisko"
          label="Nazwisko"
          value={tradingDocument.recipientLastName}
        />
        <AsyncInput
          disabled={streetMutation.isLoading}
          inProgress={streetMutation.isLoading}
          onChange={recipientStreet =>
            streetMutation.mutate({ id: tradingDocument.id, recipientStreet })
          }
          overwrites={asyncInputOverrides}
          placeholder="Adres (ulica)"
          label="Adres"
          value={tradingDocument.recipientStreet}
        />
        <div className="d-flex">
          <AsyncInput
            disabled={postCodeMutation.isLoading}
            inProgress={postCodeMutation.isLoading}
            onChange={recipientPostCode =>
              postCodeMutation.mutate({ id: tradingDocument.id, recipientPostCode })
            }
            overwrites={{
              container: { className: styles.postCodeContainer },
              input: { className: styles.input },
              floatingLabel: { className: styles.floatingLabel },
            }}
            placeholder="Kod pocztowy"
            label="Kod pocztowy"
            value={tradingDocument.recipientPostCode}
          />
          <AsyncInput
            disabled={cityMutation.isLoading}
            inProgress={cityMutation.isLoading}
            onChange={recipientCity =>
              cityMutation.mutate({ id: tradingDocument.id, recipientCity })
            }
            overwrites={asyncInputOverrides}
            placeholder="Miasto"
            label="Miasto"
            value={tradingDocument.recipientCity}
          />
        </div>
        <AsyncInput
          disabled={phoneMutation.isLoading}
          inProgress={phoneMutation.isLoading}
          onChange={recipientPhone =>
            phoneMutation.mutate({ id: tradingDocument.id, recipientPhone })
          }
          overwrites={asyncInputOverrides}
          placeholder="Telefon"
          label="Telefon"
          value={tradingDocument.recipientPhone}
        />
        <AsyncInput
          disabled={emailMutation.isLoading}
          inProgress={emailMutation.isLoading}
          onChange={recipientEmail =>
            emailMutation.mutate({ id: tradingDocument.id, recipientEmail })
          }
          overwrites={asyncInputOverrides}
          placeholder="Email"
          label="Email"
          value={tradingDocument.recipientEmail}
        />
        <AsyncInput
          disabled={taxIdMutation.isLoading}
          inProgress={taxIdMutation.isLoading}
          onChange={buyerTaxId => taxIdMutation.mutate({ id: tradingDocument.id, buyerTaxId })}
          overwrites={asyncInputOverrides}
          placeholder="NIP"
          label="NIP"
          value={tradingDocument.buyerTaxId}
        />
        <AsyncInput
          disabled={bankAccountNumberMutation.isLoading}
          inProgress={bankAccountNumberMutation.isLoading}
          onChange={recipientBankAccountNumber =>
            bankAccountNumberMutation.mutate({
              id: tradingDocument.id,
              recipientBankAccountNumber,
            })
          }
          overwrites={asyncInputOverrides}
          placeholder="Nr konta bankowego"
          label="Nr konta bankowego"
          value={tradingDocument.recipientBankAccountNumber}
        />
      </div>
    </RightPanelSection>
  );
};

const asyncInputOverrides = {
  container: { className: styles.inputContainer },
  input: { className: styles.input },
  floatingLabel: { className: styles.floatingLabel },
};
