import { Pagination, UUID } from "api/types";
import { queryFetch } from "apiConnectors/queryFetch";
import { ApiFetcher } from "hooks/createApiQuery";
import {
  BulkUpdateCustomMadePackagesPayload,
  BulkUpdateIndexesPackagesNumberPayload,
  BulkUpdateIndexesPayload,
  GeneratePackagesForIndexesPayload,
  IndexCustomMadePackagesPayload,
  IndexDetails,
  IndexListItem,
  IndexPackageListItem,
  IndexStatistics,
  RemovePackageFromIndexPayload,
} from "./models";
import { indexesKeys } from "./keys";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";

const getIndexes = (search: string): ApiFetcher<Pagination<IndexListItem>> => ({
  key: indexesKeys.indexes.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/manufacturing" + search,
    }),
});

const getIndex = (indexId: string): ApiFetcher<IndexDetails> => ({
  key: indexesKeys.indexes.details(indexId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/indexes/manufacturing/" + indexId,
    }),
});

const getIndexStatistics = (indexId: string): ApiFetcher<IndexStatistics> => ({
  key: indexesKeys.indexes.indexStatistics(indexId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/indexes/manufacturing/${indexId}/statistics`,
    }),
});

const patchIndex = (data: PartialOf<IndexDetails>, indexId: UUID) =>
  queryFetch<IndexDetails>({
    method: "PATCH",
    url: "/indexes/manufacturing/" + indexId,
    data: parsePatchData(data),
  });

const getIndexPackages = (search: string): ApiFetcher<Pagination<IndexPackageListItem>> => ({
  key: indexesKeys.packages.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/packages/manufacturing-index" + search,
    }),
});

const patchIndexCustomMadePackages = (data: IndexCustomMadePackagesPayload, search: string) =>
  queryFetch<void>({
    method: "PATCH",
    url: "/packages/bulk-update-custom-made" + search,
    data,
  });

const postGeneratePackagesForIndexes = (data: GeneratePackagesForIndexesPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/packages/create-from-indexes",
    data,
  });

const removePackageFromIndex = (data: RemovePackageFromIndexPayload) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/packages/assign",
    data,
  });

const bulkUpdateIndexes = (
  data: Assign<PartialOf<BulkUpdateIndexesPayload>, { indexesIds: number[] }>,
) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/indexes/bulk-update",
    data,
  });

const bulkUpdateIndexesPackagesNumber = (
  data: Assign<BulkUpdateIndexesPackagesNumberPayload, { indexesIds: number[] }>,
) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/indexes/update-number-of-packages",
    data,
  });

const bulkUpdatePackagesInIndexes = (
  data: Assign<BulkUpdateCustomMadePackagesPayload, { indexesIds: number[] }>,
) =>
  queryFetch<{ message: "ok" }>({
    method: "POST",
    url: "/packages/bulk-update-packages-in-indexes",
    data,
  });

export const indexesApi = {
  bulkUpdateIndexes,
  bulkUpdatePackagesInIndexes,
  bulkUpdateIndexesPackagesNumber,
  getIndex,
  getIndexStatistics,
  getIndexes,
  getIndexPackages,
  patchIndex,
  patchIndexCustomMadePackages,
  postGeneratePackagesForIndexes,
  removePackageFromIndex,
};
